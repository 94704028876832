.slick-prev:before, .slick-next:before {
    color: #09529b !important;
    font-size: 25px;
  }


.tga{
  width: 100%;
}

.scrollable-div {
    background-color: #f1f1f1;
    width:550px;
    height: 350px;
    overflow: auto;
    text-align: left;
    padding: 20px;
    border-radius:10px;
  }

.carousel{
    width: 350;
    height: 225;
    object-fit:"cover";
    border-radius:10
}

